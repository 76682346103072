.Mitr {
  font-weight: 700;
  font-family: "Mitr";
}
.sketch1-img-mobile {
  display: none;
}
.mobile-view {
  display: none;
}
.my-button:hover {
  background-color: #36a19a !important;
  border-color: #34a39c !important;
  color: white !important;
}

.home-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  min-height: 50em;
}
.about-img {
  position: absolute;
  top: -9em;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 58em;
}
.header-logo {
  /*margin-top: 4em;*/
}
#Header {
  background-color: #7a2d58;
}
.ant-popover-inner {
  background-color: #ea8c0e !important;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
.ant-input::placeholder {
  text-overflow: ellipsis;
  color: white !important;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  display: contents;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  margin-left: 12px;
  margin-top: 1em;
  white-space: unset;
}
.react-slideshow-container .default-nav {
  margin: 0.2em !important;
  height: 55px !important;
  background: rgba(0, 0, 0, 0.1) !important;
  width: 55px !important;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25) !important;
}
.bm-item,
.sub-item {
  display: block;
  padding: 18px 28px !important;
  cursor: pointer;
}
textarea.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 1.2em !important;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  padding: 4px 0px;
  background-color: #d9d9d9a2 !important;
  background-image: none;
  border: none;
  border-bottom: 0px solid white;
  border-radius: 2px;
  font-weight: 700 !important;
  font-size: 15px !important;
  padding-top: 1em !important;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: white !important;
  box-shadow: none !important;
}
.ant-form-item-explain-error {
  color: #ddd9d9 !important;
  font-weight: 400 !important;
}
.ant-card-meta-title {
  overflow: hidden;
  color: black !important;
  font-weight: 500;
  font-size: 15px !important;
  font-weight: 700 !important;
  white-space: normal !important;
}

.ant-rate {
  color: #ea8c0e !important;
  font-size: 30px !important;
}

.ant-rate-text {
  font-size: 22px !important;
  font-weight: 1000 !important;
  color: #ea8c0e !important;

  -webkit-text-stroke: 0.2px white;
}
.ant-progress-text {
  color: black !important;
  font-weight: 500;
  position: absolute;
  left: 35% !important;
  font-size: 15px !important;
  margin-top: 0.3em;
  color: white !important;
  display: inline-block !important;
}
.ant-progress-success-bg,
.ant-progress-bg {
  height: 1.5em !important;
  background-color: #2d7a75 !important;
}
.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: rgb(45, 122, 117) !important;
}
.react-responsive-modal-modal {
  max-width: 800px;
  width: 30em !important;
  padding-bottom: 4em !important;
  display: inline-block;
  text-align: center !important;
  vertical-align: middle;
  background: #ffffffe7 !important;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  border-radius: 1.5em;
  overflow-y: auto;
}

.active {
  color: rgb(234, 140, 14) !important;
}
.active-sub {
  color: #7a2d58 !important;
}
.react-responsive-modal-closeButton {
  fill: rgb(0, 0, 0) !important;
}
.bm-item,
.sub-item {
  font-size: 20px;
  font-weight: 500;

  display: block;
  padding: 14px 28px;
  cursor: pointer;
}
.Mitr-header {
  font-weight: 400;
  font-family: "Mitr";
}
.mulish-paragraph {
  font-weight: 400;
  font-family: "Mitr";
}

.mulish-paragraph-small {
  font-weight: 400;
  font-family: "Mitr";
}
.Mitr-center {
  font-weight: 400;
  font-family: "Mitr";
}
.Mitr-product-range {
  font-weight: 400;
  font-family: "Mitr";
}
.Mitr-footer {
  font-weight: 400;
  font-family: "Mitr";
}
.Poppins {
  font-family: "Poppins";
}
.Chela {
  font-family: "Chela One";
}
.Caveat {
  font-family: "Caveat";
}
.Grace {
  font-family: "Covered By Your Grace";
}
.Condiment {
  font-family: "Condiment";
}
.category-card-hover:hover {
  transition: 0.2s;
  filter: invert(1.2);
}
.alice-carousel__dots {
  height: 1em;
  padding-top: 0em !important;

  background: #feec80 !important;
}
.alice-carousel__dots-item:not(.__custom) {
  background-color: rgba(45, 122, 117, 0.185) !important;
}
.alice-carousel__dots-item:not(.__custom) {
  margin-bottom: 0.3em !important;
}
.ant-card-meta-title {
  font-weight: 700 !important;
  font-size: 12px !important;
}
#nav-toggle span {
  margin-left: -1.3em;
  margin-top: -0.2em;
}
.burger-container {
  position: fixed;
  right: 6vw;
  z-index: 99;
  margin-top: 30px;
  background: #ffffff;
  padding: 3em;
  border-radius: 10em;
  cursor: pointer;
}
#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 4px;
  width: 38px;
  background: #000000;
  position: absolute;
  display: block;
  content: "";
}
#nav-toggle span:before {
  top: -13px;
}
#nav-toggle span:after {
  bottom: -13px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  z-index: 9999 !important;
}
@media (max-width: 1100px) {
  .ant-rate {
    color: #ea8c0e !important;
    font-size: 20px !important;
  }
  .ant-rate-text {
    font-size: 19px !important;
    font-weight: 700 !important;
    color: #c46f00 !important;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    background-color: #ea8c0e !important;
  }
  .ant-dropdown-menu {
    background-color: #ea8c0e !important;
  }
  .react-slideshow-container .default-nav {
    background: rgba(255, 255, 255, 0.432) !important;
  }
  .ant-popover-open .burger-container {
    z-index: 9999 !important;
  }
  .ant-popover-placement-bottom,
  .ant-popover-placement-bottomLeft,
  .ant-popover-placement-bottomRight {
    position: fixed !important;
    z-index: 9999 !important;
    transform-origin: 204.219px -4px !important;
    top: 50px !important;
  }
  .react-responsive-modal-modal {
    max-width: 800px;
    width: auto !important;
    padding-bottom: 4em !important;
    display: inline-block;
    text-align: center !important;
    vertical-align: middle;
    background: #ffffffe7 !important;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    margin: 1.2rem;
    padding: 1.2rem;
    position: relative;
    border-radius: 1.5em;
    overflow-y: auto;
  }
  #Header {
    position: fixed;
    z-index: 9999;
    width: 100vw;
    top: 0em;
  }
  .burger-container {
    position: relative;
    right: 4vw;
    z-index: 99;
    margin-top: 0px;
    background: transparent;
    padding: 2.2em;
    border-radius: 10em;
    cursor: pointer;
  }
  #nav-toggle span,
  #nav-toggle span:before,
  #nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 3px;
    width: 30px;
    background: #ffffff;
    position: absolute;
    display: block;
    content: "";
  }
  #nav-toggle span {
    margin-left: 1em;
    margin-top: -0.1em;
  }
  #nav-toggle span:before {
    top: -10px;
  }
  #nav-toggle span:after {
    bottom: -10px;
  }
  .alice-carousel__dots {
    height: 2em;
    display: none;
    padding-top: 0em !important;
    transform: scale(1);
    background: #184199ce !important;
  }

  .alice-carousel__stage-item {
    margin-right: 3.5em !important;
    margin-left: -3.9em !important;
  }
  .alice-carousel {
    position: relative;
    width: 100%;
    overflow: hidden !important;
    margin: auto;
    direction: ltr;
  }
  .desktop-view {
    display: none;
  }
  .mobile-view {
    display: inline;
  }
  .home-img {
    position: absolute;
    top: 2.5vh;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 95vh;
    min-height: 10em;
  }
  .about-img {
    position: absolute;
    top: -9em;
    left: 0;
    z-index: -1;
    width: 100vw;
    height: 62em;
  }
  .hea .category-card-hover {
    width: 93vw !important;
  }
  .mobile-display {
    display: none;
  }
  .header-logo {
    width: 11em !important;
    padding-top: 0.5em;
    padding-bottom: 0.2em;
  }
  .page-header-min {
    height: 10em !important;
  }
  .Mitr-header {
    text-align: center;
    margin-top: 12vh !important;
    line-height: 1;
    font-size: 6.5vh !important;
    line-height: 1.2;
    font-weight: 400;
    font-family: "Mitr";
  }
  .mulish-paragraph {
    font-size: 5vh !important;
    margin-top: 0.2em !important;
    text-align: center;
    line-height: 1;
    font-weight: 400;
    font-family: "Mitr";
  }
  .home-col {
    height: 100vh;
  }
  .mulish-paragraph-small {
    text-align: center;
    font-weight: 400;
    font-family: "Mitr";
  }
  .home-button {
    margin-top: 0.5em !important;
    text-align: center;
    margin: auto;
    line-height: 1;
    font-weight: 400;
    font-family: "Mitr";
  }

  .about-row {
    margin-top: 0em !important;
    margin: auto;
    text-align: center;
  }
  .about-row-cert {
    margin: auto;
    margin-top: 2em !important;
    text-align: center;
  }
  .Mitr-center {
    padding-right: 0em !important;
    font-weight: 400;
    font-family: "Mitr";
  }
  .Mitr-product-range {
    margin-top: -1.5em !important;
    font-weight: 400;
    font-family: "Mitr";
  }
  .products-img {
    margin-top: 0em !important;
  }
  .products-img-1 {
    margin-top: -3em !important;
  }
  .view-more-button {
    margin-top: -3em !important;
  }
  .hori-line {
    display: none;
  }
  .gallery-banner {
    text-align: center;
  }
  .gallery-banner-img {
    width: 100%;
  }
  .gallery-container {
    margin-top: 3em !important;
  }
  .footer-div {
    height: 16.5em !important;
  }
  .footer-logo {
    text-align: center;
  }
  .Mitr-footer {
    font-weight: 400;
    padding-right: 0 !important;
    font-family: "Mitr";
  }
  .footer-links-company {
    width: 85vw !important;
    text-align: center !important;
  }
  .contact-image {
    width: 100vw !important;
    margin-top: 7em !important;
  }
  .gallery-paragraph {
    padding: 0em !important;
    padding-top: 1em !important;
  }
  .Mitr-hidden {
    display: none;
  }
  .sketch1-img-mobile {
    display: inline;
  }
  .sketch1-img {
    width: 100vw !important;
    height: 150vh !important;
    left: 0 !important;
    top: 5em;
  }
  .other-button {
    margin-top: 1.5em !important;
    margin-bottom: 1.5em !important;
  }
}
